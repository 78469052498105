import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import "./pricing.scss";
import { BuryingPointFc } from "../../components/buryingPoint";
import helpIcon from '../../assets/images/pricing_page/help.png'
// import phone from '../../assets/images/phone.png'
import yingImg from '../../assets/images/ying.png'
import phone from '../../assets/images/phone000.png'
import { Modal, Tooltip } from "antd";


const PricingPage = () => {
  const ORI_MONTH_PRICE = '199.99'
  const MONTHLY_PRICE_LIST = ['0.00', '299.99', '899.99']
  const ANNUALLY_PRICE_LIST = ['0.00', '2999.99', '8999.99']
  const [priceList, setPriceList] = React.useState<String[]>(MONTHLY_PRICE_LIST)
  const [monthlyActive, setMonthlyActive] = React.useState<boolean>(true)
  const [monthOrYear, setMonthOrYear] = React.useState<string>('/每月')
  const [ helpStatus1, setHelpStatus1 ] = React.useState<boolean>(false);
  const [ helpStatus2, setHelpStatus2 ] = React.useState<boolean>(false);
  const [ promptBox,setPromptBox ] = React.useState<boolean>(false);
  const [ windowBoxWidth, setWindowBoxWidth ] = React.useState<boolean>(false);
  const [ promptModal, setPromptModal ] = React.useState<boolean>(false)
  const selectPeriod = (period: '每月' | '每年') => {
    console.log(`click${period}`);
    if (period == '每月') {
      setMonthlyActive(true)
      setMonthOrYear('/每月')
      setPriceList(MONTHLY_PRICE_LIST)
    } else {
      setMonthlyActive(false)
      setMonthOrYear('/每年')
      setPriceList(ANNUALLY_PRICE_LIST)
    }
  }
  const device = ()=>{
    const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i)) {
        // 用户代理字符串包含手机浏览器的标识符，这是手机访问
        // 你可以在这里执行手机特定的操作
        alert('协助商家开展前两个项目运营、管理，以及第一批创作者导流')
    } else {
        // console.log("这是桌面访问");
    }
    
  }
  const accompanyingCopyWriting = () => {
    
    return <div className="writting" style={{ position: 'absolute' }}>
            <p>如果您单次订阅“标准年套餐”，我们附赠以下配套服务:</p>
            <p>1. 《新媒体运营方案建议书》一份。基于您的业务，为您量身定制一份解决方案，以达到更快更好的营销效果。</p>
            <p>2.  全程陪跑! 全程协助您开展前两个项目的运营、管理工作，帮您导流及培训第一批创作者（含员工）。</p>
            <p>客户经理：<img className="writtingImg" src={phone}/>,微信同号</p>
          </div>
  }
  const accompanyingCopyWritingDemo = ()=>{
    return <div className="writtingDemo" >
            <p>如果您单次订阅“标准年套餐”，我们附赠以下配套服务:</p>
            <p>1. 《新媒体运营方案建议书》一份。基于您的业务，为您量身定制一份解决方案，以达到更快更好的营销效果。</p>
            <p>2.  全程陪跑! 全程协助您开展前两个项目的运营、管理工作，帮您导流及培训第一批创作者（含员工）。</p>
            <p>客户经理：<img className="writtingImg" src={phone}/>,微信同号</p>
          </div>
  }
  let windowBoxStatus = true;
  const windowBoxStatusFc = ()=>{
    if(windowBoxStatus){
      if(window.innerWidth >= 480){
        setWindowBoxWidth(true);
      }else{
        setWindowBoxWidth(false);
        setPromptModal(true);
      }
      windowBoxStatus = false
      setTimeout(() => {
        windowBoxStatus = true
      }, 500);
    }
  }


  return (
    <Layout>
      <Seo title="定价" />
      {/*  pricing */}
      <div id="pricing" className=" text-light text-center position-relative">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            {/*  logo */}
            <div className="text-center mb-4" style={{ marginTop: '4rem' }}>
              <StaticImage src="../../assets/images/pricing_page/subscribe.png" style={{ maxWidth: '30px' }} alt="" />
            </div>
            {/*  Title */}
            <div className="text-center mb-4">
              <h2 style={{ color: '#000' }}>
                {/* Marketing growth needs a good tool */}
                低成本获客，撬动品牌增长
              </h2>
              <h6 style={{ color: '#6d6d6d' }}>
                {/* Pay for what you need */}
                为您需要的服务付费
              </h6>
            </div>
            {/*  btn */}
            <div
              id="selecttime"
              className="text-center mb-5" role="group">
              <div className={monthlyActive ? 'btn btn-secondary' : 'btn btn-light'}
                style={{
                  border:'1px solid #bfbfbf',
                  padding: '5px 20px',
                  marginRight: '10px',
                  display: 'inline-block',
                }}
                onClick={() => { selectPeriod('每月'); BuryingPointFc('VO','VO00300100200101',{action:'点击每月'}) }}>
                {/* Monthly */}
                每月
              </div>
              <div
                className={monthlyActive ? 'btn btn-light' : 'btn btn-secondary'}
                style={{ border: '1px solid #bfbfbf', padding: '5px 20px', display: 'inline-block', }}
                
                onClick={() => { selectPeriod('每年');BuryingPointFc('VO','VO00300100200201',{action:'点击每年'}) }}>
                {/* Annualy  */}
                每年
              </div>
            </div>
          </div>

          {/*  goods list */}
          <div className="my-text-mobile-left">
            <div className="row monthly" style={{ justifyContent: 'space-between' }}>

              {/*  goods content */}
              <div className="col-md-4">
                {/*  goods container */}
                <div className="card-body text-center goodscontainer">
                  {/*  goods title */}
                  <h3 className="card-title my-2" style={{ color: '#000', fontWeight: '700', }}>
                    {/* Starter */}
                    体验
                  </h3>
                  {/*  describe */}
                  <div className="my-2" style={{ color: '#8b969c', padding: '0 7px' }}>
                    <div style={{ width: '100%' }}>
                      {/* For smaller teams getting started with content marketing. */}
                      新用户免费试用7天
                    </div>
                  </div>
                  {/*  pricing */}
                  <div>
                    <div style={{ display: 'inline-block', color: '#6e6969', position: 'relative', left: '-5px', top: '-8px' }}>￥</div>
                    <div className="price" style={{ display: 'inline-block', color: '#000', fontSize: '3rem' }}>{priceList[0]}</div>
                    <div style={{ display: 'inline-block' }} className="discount">
                      {/* <div style={{ color: '#4f4f4f', textAlign: 'center', marginLeft: '.5rem'}}><s>￥{ORI_MONTH_PRICE}</s></div> */}
                      {/* <div style={{ color: '#96A3B3', textAlign: 'center', marginLeft: '.5rem' }}> */}
                        {/* Limited time */}
                        {/* 限时折扣 */}
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="my-4" style={{ color: '#676767', marginTop: '0 !important' }}>
                  <span className="uodatetext">{'7天'}</span>
                  </div>
                  {/*  Buy now */}
                  <div>
                  
                    <a onClick={()=>BuryingPointFc('OD','OD00300100200102',{action:'点击入门的立即购买'})} className="btn" style={{ padding: '5px 3rem', background: '#2878FC', color: '#fff', }} href="http://j.lidebiji.com/user/register"
                      target="_blank">
                      {/* Buy now */}
                      立即试用
                    </a>
                  </div>
                  {/*  Start free */}
                  <div className="my-4">
                    {/* <a onClick={()=>BuryingPointFc('VO','VO00300100200501',{action:'点击定价页面的“商家试用'})} href="http://j.lidebiji.com/user/login/" target="_blank" style={{ textDecoration: 'none' }}> */}
                      {/* Start free trial */}
                      {/* 商家试用
                    </a> */}
                    <button className="btn" style={{cursor:'initial'}}>
                    </button>
                  </div>
                  {/*  list */}
                  <div>
                    {/*  project */}
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* project */}
                        项目
                      </span>
                      <span className="goodslistnum">1</span>

                    </div>
                    {/*  tasks */}
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* merchandises(tasks) */}
                        任务
                      </span>
                      <span className="goodslistnum">5</span>

                    </div>
                    {/*  storage */}
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* storage */}
                        存储
                      </span>
                      <span className="goodslistnum">2G</span>

                    </div>
                    {/*  creators */}
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* creators */}
                        创作者
                      </span>
                      <span className="goodslistnum">10</span>
                    </div>
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* creators */}
                        平台是否导流创作者
                      </span>
                      <span className="goodslistnum">-</span>
                    </div>
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* creators */}
                        里德笔记帮助中心
                      </span>
                      <span className="goodslistnum"><StaticImage src="../../assets/images/pricing_page/check.svg" alt={""} style={{ color: '#00c761' }} /></span>
                    </div>
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* creators */}
                        在线客服
                      </span>
                      <span className="goodslistnum"><StaticImage src="../../assets/images/pricing_page/check.svg" alt={""} style={{ color: '#00c761' }} /></span>
                    </div>
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* creators */}
                        专属客户经理
                      </span>
                      <span className="goodslistnum">-</span>
                    </div>
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* creators */}
                        项目建设及培训
                      </span>
                      <span className="goodslistnum">-</span>
                    </div>
                  </div>
                </div>
              </div>

              {/*  goods content */}
              <div className="col-md-4 widthadaptation">

                {/*  goods container */}
                <div className="card-body text-center goodscontainer">
                  {/*  goods title */}
                  <h3 className="card-title my-2" style={{ color: '#000', fontWeight: '700' }}>
                    {/* Standard */}
                    基础
                  </h3>
                  {/*  describe */}
                  <div className="my-2" style={{ color: '#8b969c', padding: '0 7px' }}>
                    <div style={{ width: '100%' }}>
                      {/* For small business with content */}
                      适用于中小企业，或者营销部门
                    </div>
                  </div>
                  {/*  pricing */}
                  <div>
                    <span style={{ display: 'inline-block', color: '#6e6969', position: 'relative', left: '-5px', top: '-8px' }}>￥</span>
                    <span className="price" style={{ display: 'inline-block', color: '#000', fontSize: '3rem' }}>{priceList[1]}</span>
                  </div>
                  <div className="my-4" style={{ color: '#676767', marginTop: '0 !important' }}>
                    <span className="uodatetext">{monthOrYear}</span>
                  </div>
                  {/*  Buy now */}
                  <div>
                  
                    <a onClick={()=>BuryingPointFc('OD','OD00300100200104',{action:'点击基础-立即购买'})} className="btn" style={{ padding: '5px 3rem', background: '#2878FC', color: '#fff', }} href="http://j.lidebiji.com/user/register"
                      target="_blank">
                      {/* Buy now */}
                      立即购买
                    </a>
                  </div>
                  {/*  Start free */}
                  <div className="my-4">
                    <button className="btn" style={{cursor:'initial'}}>
                    </button>
                  </div>
                  {/*  list */}
                  <div>
                    {/*  project */}
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* projects */}
                        项目
                      </span>
                      <span className="goodslistnum">2</span>

                    </div>
                    {/*  tasks */}
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* merchandises(task) */}
                        任务
                      </span>
                      <span className="goodslistnum">50</span>

                    </div>
                    {/*  storage */}
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* storage */}
                        存储
                      </span>
                      <span className="goodslistnum">5G</span>
                    </div>
                    {/*  creators */}
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* creators */}
                        创作者
                      </span>
                      <span className="goodslistnum">100</span>

                    </div>
                    <div className={`goodsborder goodsborderPosition ${ helpStatus2 && 'help' }`}>
                      <span className="goodsliststr">
                        {/* creators */}
                        平台是否导流创作者
                        <img onClick={()=> setHelpStatus2(!helpStatus2)} style={{ width: '7%', marginLeft: '5px', cursor: 'pointer' }} src={helpIcon} />
                      </span>
                      <span className="goodslistnum">
                        <StaticImage src="../../assets/images/pricing_page/check.svg" alt={""} style={{ color: '#00c761' }} />
                      </span>
                      {
                        <span className="tooltips">
                          是的，但取决于您的任务类型和定价
                        </span>
                      }
                    </div>
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* creators */}
                        里德笔记帮助中心
                      </span>
                      <span className="goodslistnum"><StaticImage src="../../assets/images/pricing_page/check.svg" alt={""} style={{ color: '#00c761' }} /></span>
                    </div>
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* creators */}
                        在线客服
                      </span>
                      <span className="goodslistnum"><StaticImage src="../../assets/images/pricing_page/check.svg" alt={""} style={{ color: '#00c761' }} /></span>
                    </div>
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* creators */}
                        专属客户经理
                      </span>
                      <span className="goodslistnum">-</span>
                    </div>
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* creators */}
                        项目建设及培训
                      </span>
                      <span className="goodslistnum">-</span>
                    </div>
                  </div>
                </div>

              </div>

              {/*  goods content */}
              <div className="col-md-4">

                {/*  goods container */}
                <div className="card-body text-center goodscontainer">
                  {/*  goods title */}
                  <h3 className="card-title my-2" style={{ color: '#000', fontWeight: '700' }}>
                    {/* Advanced */}
                    标准
                  </h3>
                  {/*  describe */}
                  <div className="my-2" style={{ color: '#8b969c', padding: '0 7px' }}>
                    <div style={{ width: '100%' }}>
                      {/* For business with content marketing teams and creators. */}
                      适用于专业内容运营团队
                    </div>
                  </div>
                  {/*  pricing */}
                  <div>
                    <span style={{ display: 'inline-block', color: '#6e6969', position: 'relative', left: '-5px', top: '-8px' }}>￥</span>
                    <span className="price" style={{ display: 'inline-block', color: '#000', fontSize: '3rem' }}>{priceList[2]}</span>
                  </div>
                  <div className="my-4" style={{ color: '#676767', marginTop: '0 !important' }}>
                    <span className="uodatetext">{monthOrYear}</span>
                  </div>
                  {/*  Buy now */}
                  <div>
                    <a onClick={()=>BuryingPointFc('OD','OD00300100200105',{action:'点击标准-立即购买'})} className="btn" style={{ padding: '5px 3rem', background: '#2878FC', color: '#fff', }} href="http://j.lidebiji.com/user/register"
                      target="_blank">
                      {/* Buy now */}
                      立即购买
                    </a>
                  </div>
                  {/*  Start free */}
                  <div className="my-4" style={{ position: 'relative' }}>
                    {
                      !monthlyActive ?  <a onMouseMove={()=> {windowBoxStatusFc();setPromptBox(true)}} onMouseLeave={()=>setPromptBox(false)} onClick={()=> {BuryingPointFc('VO','VO00300100200105',{action:'点击了全程陪跑'})}} target="_blank" style={{ textDecoration: 'none', color:'#f5000a',cursor:'pointer' }}>
                                          全程陪跑
                                        </a> 
                    : 
                            <button className="btn" style={{cursor:'initial'}}>
                            </button>
                    }
                    {
                      (promptBox && windowBoxWidth) && accompanyingCopyWriting()
                    }
                    {
                      <Modal title='全程陪跑' open={promptModal} onCancel={()=>setPromptModal(false)} footer={null}>
                      <div>
                        <p>如果您单次订阅“标准年套餐”，我们附赠以下配套服务:</p>
                        <p>1. 《新媒体运营方案建议书》一份。基于您的业务，为您量身定制一份解决方案，以达到更快更好的营销效果。</p>
                        <p>2.  全程陪跑! 全程协助您开展前两个项目的运营、管理工作，帮您导流及培训第一批创作者（含员工）。</p>
                        <p style={{ display: 'flex', alignItems: 'center'}}>客户经理：<img style={{ width: '25%' }} className="writtingImg" src={phone}/>,微信同号</p>
                      </div>
                      </Modal>
                    }
                  </div>
                  {/*  list */}
                  <div>
                    {/*  project */}
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* projects */}
                        项目
                      </span>
                      <span className="goodslistnum">5</span>
                    </div>
                    {/*  tasks */}
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* merchandises(task) */}
                        任务
                      </span>
                      <span className="goodslistnum">100</span>

                    </div>
                    {/*  storage */}
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* storage */}
                        存储
                      </span>
                      <span className="goodslistnum">10G</span>

                    </div>
                    {/*  creators */}
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* creators */}
                        创作者
                      </span>
                      <span className="goodslistnum">500</span>
                    </div>
                    <div className={`goodsborder goodsborderPosition ${ helpStatus1 && 'help' }`}>
                      <span className="goodsliststr">
                        {/* creators */}
                        平台是否导流创作者
                        <img onClick={()=> setHelpStatus1(!helpStatus1)} style={{ width: '7%', marginLeft: '5px', cursor: 'pointer' }} src={helpIcon} />
                      </span>
                      <span className="goodslistnum">
                        <StaticImage src="../../assets/images/pricing_page/check.svg" alt={""} style={{ color: '#00c761' }} />
                      </span>
                      {
                        <span className="tooltips">
                          是的，但取决于您的任务类型和定价
                        </span>
                      }
                      
                    </div>
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* creators */}
                        里德笔记帮助中心
                      </span>
                      <span className="goodslistnum"><StaticImage src="../../assets/images/pricing_page/check.svg" alt={""} style={{ color: '#00c761' }} /></span>
                    </div>
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* creators */}
                        在线客服
                      </span>
                      <span className="goodslistnum"><StaticImage src="../../assets/images/pricing_page/check.svg" alt={""} style={{ color: '#00c761' }} /></span>
                    </div>
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* creators */}
                        专属客户经理
                      </span>
                      <span className="goodslistnum"> <StaticImage src="../../assets/images/pricing_page/check.svg" alt={""} style={{ color: '#00c761' }} /></span>
                    </div>
                    <div className="goodsborder">
                      <span className="goodsliststr">
                        {/* creators */}
                        项目建设及培训
                      </span>
                      <span className="goodslistnum"> <StaticImage src="../../assets/images/pricing_page/check.svg" alt={""} style={{ color: '#00c761' }} /></span>
                    </div>
                  </div>
                </div>

              </div>

            </div>
 

                      {/* 定制项目原地方 */}
                                 {/*  Need a custom project */}
            {/* <div className="goodsfooter"> */}
              {/* <div style={{ textAlign: 'center' }}> */}
                {/* <h3> */}
                  {/* Need a customized project? */}
                  {/* 需要定制项目？ */}
                {/* </h3> */}
                {/* <div> */}
                  {/* Need more tasks, creators, or storage space? */}
                  {/* 需要更多任务、创作者或存储空间？ */}
                {/* </div> */}
                {/* <div> */}
                  {/* please feel free to contact US! We can customize a project solution for you. */}
                  {/* 没问题！ 我们可以为您定制专属项目解决方案。 */}
                {/* </div>
                <div style={{ marginTop: '25px', paddingBottom: '40px' }}>
                  <a className="btn btn-lg"
                    style={{ padding: '5px 3rem', background: '#2878FC', color: '#fff', border: '2px solid #2878FC' }}
                    href="mailto:bd@leadingcloud.xyz"
                    onClick={()=>BuryingPointFc('VO','VO00300100200301',{action:'点击去获取'})}
                  > */}
                    {/* Get in touch */}
                    {/* 去获取
                  </a>
                </div>
              </div>

            </div> */}


          </div>

        </div>
      </div>
      {/*  offer */}
      <div id="offer row">
        <div className="row row-no-gutters container" style={{ margin: '3rem auto' }}>
          <div className="col-xs-6 col-md-4">
            <StaticImage src="../../assets/images/pricing_page/Pricing.png" className="img-fluid" style={{ float: 'right' }} alt={""} />
          </div>
          <div className="col-xs-12 col-md-8" style={{ marginTop: '4rem' }}>
            <h3 className="offerTitle">
              {/* What can ContentWork offer you? */}
              里德笔记能为您提供什么？
            </h3>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ul style={{ color: '#aaaaaa' }}>
                <li>
                  {/* Search Engine Optimization (SEO) */}
                  低成本获客
                </li>
                <li style={{margin:'20px 0'}}>
                  {/* Social Media Management */}
                  提升品牌影响力，SEO优化等
                </li>
                <li>
                  {/* Content Curation */}
                  与时俱进的新媒体营销流程
                </li>
                <li style={{margin:'20px 0'}}>
                  {/* Content Curation */}
                  为部分客户定制 "新媒体运营方案"，<Tooltip overlayClassName='TooltipClass' trigger='hover' title={accompanyingCopyWritingDemo}><span style={{ color: 'blue',cursor: 'pointer' }}>全程陪跑</span></Tooltip>
                </li>
              </ul>
              <ul style={{ color: '#aaaaaa', marginLeft: '1.5rem;' }}>
                <li>
                  {/* Keyword Research */}
                  解决新媒体招人、用人、管人难的问题
                </li>
                <li style={{margin:'20px 0'}}>
                  {/* Personal Brand Development */}
                  快速对接大量自媒体、博主、创作者
                </li>
                <li>
                  {/* Online Reputation Management */}
                  快速对接海量媒体资源，批发价
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/*  Book a demo */}
      {/* <div id="bookADemo" className="bg-dark">

        <div className="container">
          <div className="row" style={{ padding: '3.5rem 0' }}>
            <div className="col-md" style={{ color: '#fff' }}>
              <h5> */}
                {/* Not sure which project you need? */}
                {/* 不确定您需要哪个项目？
              </h5>
              <p> */}
                {/* Book a call to discuss your company and get a personalized demo */}
                {/* 想要获得专属个性化演示，请与我们取得联系。
              </p>
              <a className="btn"
                style={{ padding: '5px 3rem', background: '#2878FC', color: '#fff' }}
                href="mailto:bd@leadingcloud.xyz"
                onClick={()=>BuryingPointFc('VO','VO00300100200402',{action:'点击请求演示'})}
              > */}
                {/* Request demo */}
                {/* 请求演示
              </a>
            </div>
          </div>
        </div>
      </div> */}




      { /*  Q&A Accordion */ } 
      <div id="questions" style={{padding:'3rem 0'}}>
        <div className="container">
          <h2 className="text-center mb-4">
            {/* Frequently Asked Questions */}
            常见问答
          </h2>
          <div className="accordion accordion-flush" id="questions">
            <div className="accordion-item">
              <h2 className="accordion-header">
              
                <button onClick={()=>BuryingPointFc('VO','VO00300100200501',{action:'点击常见问答的“我可以免费试用里德笔记吗'})} className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#question-one">
                  {/* Can I try ContentWork for free? */}
                  我可以免费使用里德笔记吗？
                </button>
              </h2>
              <div id="question-one" className="accordion-collapse collapse" data-bs-parent="#questions">
                <div className="accordion-body">
                  {/* Yes, all you need to do is sign up for a 14-day free trial, which includes all features and no limits. After your free trial， you can then select a project to continue using your account. */}
                  登录 <a className="pricingCenter" href="http://j.lidebiji.com/user/register" target={'_blank'} style={{ color: "black" }}>“ 里德笔记商家中心 ”</a>网页，注册账号，即可获得7天的免费使用期。
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button onClick={()=>BuryingPointFc('VO','VO00300100200502',{action:'点击常见问答的“试用到期不进行订阅会怎样”'})} className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#question-two">
                  {/* What happens if I don't subscribe when my trial expires? */}
                  试用到期不进行订阅会怎样？
                </button>
              </h2>
              <div id="question-two" className="accordion-collapse collapse" data-bs-parent="#questions">
                <div className="accordion-body">
                  {/* After the trial expires, you will not subscribe. You can continue to log in to the Merchant Center to view the content of your project, but we will restrict members from joining projects, listing tasks， and other functions. */}
                  试用到期后，你需要订阅才能继续使用。原有已发布的内容不受影响。
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button onClick={()=>BuryingPointFc('VO','VO00300100200503',{action:'点击常见问答的“可以使用什么货币进行支付”'})} className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#question-three">
                  {/* What currency can be used for payment? */}
                  可以使用什么货币进行支付？
                </button>
              </h2>
              <div id="question-three" className="accordion-collapse collapse" data-bs-parent="#questions">
                <div className="accordion-body">
                  {/* The currency used for payment depends on the type of account you have set up. If the account type is a US account, it needs to be paid in USD; if the account type is a Chinese account, it needs to be paid in RMB. */}
                  支付使用的货币取决于您设定的账户类型。若账户类型为中国账户，则需要使用人民币进行支付。若账户类型为美国账户或者其他国家账户，则需要使用美元或其他货币进行支付。
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
              
                <button onClick={()=>BuryingPointFc('VO','VO00300100200504',{action:'点击常见问答的“可以升级账户吗”'})} className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#question-four">
                  {/* Can I upgrade my account? */}
                  可以升级账户吗？
                </button>
              </h2>
              <div id="question-four" className="accordion-collapse collapse" data-bs-parent="#questions">
                <div className="accordion-body">
                  {/* Of course, you can directly complete the payment by selecting the high-level subscription form in the official website price list, and the account rights will be automatically upgraded after the payment is successful. If an existing subscription does not meet your needs, please get in touch and we will customize a project for you. */}
                  您可以在商家中心 {'>'} 右上方头像 {'>'} 权益管理 {'>'} 完成支付并升级。支付成功后账户权益将会自动升级。若过程中遇到任何疑问，请您与我们取得联系。
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button onClick={()=>BuryingPointFc('VO','VO00300100200505',{action:'点击常见问答的“可以降级账户吗”'})} className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#question-five">
                  {/* Can I downgrade my account? */}
                  可以降级账户吗？
                </button>
              </h2>
              <div id="question-five" className="accordion-collapse collapse" data-bs-parent="#questions">
                <div className="accordion-body">
                  {/* If you want to keep individual items for use, please contact us, and we will downgrade your account accordingly according to your needs. */}
                  如果您所需求的功能范围有所变化，可以与我们联系，我们将根据您的需求对您的帐户进行相应的降级。
                </div>
              </div>
            </div>
            {/*  <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#question-six">
                  How do creators claim tasks?
                </button>
              </h2>
              <div id="question-six" className="accordion-collapse collapse" data-bs-parent="#questions">
                <div className="accordion-body">
                  <p>Open the ContentWork App, view the tasks you are interested in in the "Discover" menu, and apply to
                    join the channel to receive the tasks.</p>
                  <p>If you are a business's staff, please bind the project code sent from your administrator directly.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#question-seven">
                  Why doesn't the Creator Center have tasks?
                </button>
              </h2>
              <div id="question-seven" className="accordion-collapse collapse" data-bs-parent="#questions">
                <div className="accordion-body">
                  The Creator Center can only write article tasks, please join the relevant channel on the App side first.
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/*  Get in touch btn modal */}
    </Layout>
  )
}

export default PricingPage;